*{margin: 0;padding: 0;text-decoration: none !important;box-sizing: border-box; scroll-behavior: smooth;}

/*********right section*******/
body {
    background-color: #f8f9fa !important;
}
.right-nav-section {
    background-color: #ffffff !important;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 20px;
}

.icon-container {
    margin-bottom: 10px !important; /* Adjust the margin as needed */
  }
  

.profile-pic {
    text-align: center;
	    margin: 0 0 10px;
}
.profile-pic p {
    margin: 10px 0 0;
    padding-bottom: 15px; 
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid rgb(0 0 0 / 8%)
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link:hover {
    background: #00877a;
    color: #fff;
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link {
    font-size: 14px;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link:hover svg path {
    fill: #fff;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu::-webkit-scrollbar {
    width: 6px;
    border-radius:10px;
    
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu::-webkit-scrollbar-track {
    background: #f1f1f1;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.35) inset;
    border-radius:10px;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu::-webkit-scrollbar-thumb {
    background: #00877a;
    border-radius:10px;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
button#sidebarToggle {
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #00877a;
}

button#sidebarToggle svg path {
    fill: #00877a;
}
.nav-item.dropdown a {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0 !important;
    border: 1px solid #ccc;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-item.dropdown.profile a img {
    width: 100%;
}
.nav-item.dropdown a:after {
    display: none;
}
.nav-item.dropdown ul.dropdown-menu.dropdown-menu-end.show a {
    width: auto;
    height: auto;
    border: 0;
    display: block;
    border-radius: 0;
    padding: 5px !important;
}
li.notification {
    position: relative;
}

li.notification span {
    position: absolute;
    width: 8px;
    height: 8px;
    background: red;
    border-radius: 50%;
    right: 15px;
}
.dashboard-header-sec {
    position: relative;
    height: 240px;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: start;
    margin: 35px 0;
}
.dashboard-header-sec img {
    position: absolute;
    width: 100%;
    z-index: -1;
    top: 0;
    object-fit: cover;
    border-radius: 10px;
	height: 100%;
    left: 0;
    right: 0;
}
.header-content {
    padding: 0 40px;
}

.header-content h1 {
    max-width: 60%;
}
.header-content ol li {color: #fff !important;}
.test-card img {
    width: 100%;
    border-radius: 10px;
    height: 150px;
    object-fit: cover;
}
.test-card-content {
    margin: 10px 0 15px;
    text-align: center;
}

.test-card-content h3 {
    font-size: 24px;
}
.test-card-content a {
    text-decoration: none;
    display: block;
    color: #000;
    font-size: 14px;
    margin: 0 0 5px;
}

.test-card {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FBFBFB;
    padding: 15px;
	margin: 0 0 30px;
}
.test-card.all-test ul {
    list-style: none;
    padding: 0;
}
.test-card.all-test ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
	border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    padding: 12px;
}
.test-card.all-test ul li:last-child {
    border: 0;
    padding: 12px 12px 0;
}
.test-card.all-test ul li .test-listing {
    display: flex;
	align-items: center;
}
.test-card.all-test ul li .test-listing img {
    width: 60px;
    height: 60px;
    border-radius: 5px;
}
.test-card.all-test ul li .test-action a {
    width: 40px;
    height: 40px;
    border: 1px solid #00877a;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.test-card.all-test {
    padding: 0;
}

.test-card.all-test ul li .test-listing .test-card-content h3 {
    font-size: 18px;
    margin: 0;
    font-weight: 700;
}
.test-card.all-test ul li .test-listing .test-card-content {
    margin: 0;
    text-align: left;
    padding: 0 0 0 10px;
}
.test-action{text-align: center;}
.test-action a {
    text-decoration: none;
    display:inline-block;
    color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #00877a;
    text-align: center;
    padding: 10px;
    border-radius: 40px;
    font-size: 16px;
    font-weight: 600;
}
.test-card .free a{padding: 10px 20px;}
.test-action a:hover{background-color: #2D2926 !important; border: 1px solid rgba(0, 0, 0, 0.10) !important; }
.test-action a:hover svg path{fill: #fff !important;}
ol.breadcrumb {
    margin: 0 !important;
}
.myheader {
    position: fixed;
    top: 0px;
    right: 0;
    left: 0;
    z-index: 1030;
    background: white;
    height: 100px;
    padding: 14px 30px 0;
}

.top_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top_bar_inner h4 {
    font-size: 14px;
    margin: 0 !important;
}
.sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
    z-index: 999;
}


/*dashboard css code */
.top_bar{display: flex;justify-content: space-between;padding: 14px 0 0 0;}
.top_bar_inner h4{font-size: 14px;font-weight: 600;line-height: 16px;color: #2d2926;margin: 18px 0 14px;font-family: 'Raleway', sans-serif;}
.h_line{margin: 0 15px;}
.myheader {padding: 0px 30px 0;}
.event h2{margin: 40px 0 12px 0;}
.sch_event{background-color: #fff;border: 1px solid rgba(0, 0, 0, 0.10);border-radius: 10px;padding: 17px;}
.july_num{padding: 12px 19px;border: 1px solid rgba(0, 0, 0, 0.10);border-radius: 10px;}
.july_num h2{color: #00877a;font-size: 16px;font-weight: 800;line-height: 20px;font-family: 'Urbanist', sans-serif;margin: 0;}
.july_num h4{color: #000;font-size: 12px;font-weight: 800;line-height: 16px;font-family: 'Urbanist', sans-serif;margin: 0;}
.evnt_head h2{margin: 0 0 7px 25px;font-size: 18px;font-weight: 800;line-height: 25px;color: #000;font-family: 'Urbanist', sans-serif;}
.evnt_head h4{margin: 0 0 0 25px    ;font-size: 12px;font-weight: 600;line-height: 18px;color: #2d2926;font-family: 'Raleway', sans-serif;}
.sch_event_inner{display: flex;align-items: center;}
.sch_event_main{display: flex;align-items: center; justify-content: space-between;}
.sch_view button{padding: 11px 34px;background-color: #fff;border-radius: 58px;border: 1px solid #00877a;color: #00877a;font-size: 14px;font-weight: 600;line-height: 16px;font-family: 'Urbanist', sans-serif;}
.sch_view button:hover{background-color:#2D2926;color: #fff;border: 1px solid #2d2926;}
.pagin{display: flex;align-items: center;justify-content: end;}
.listleft_arrow{border: 2px solid rgba(0, 0, 0, 0.10);height: 30px; width: 30px;border-radius: 30px;display: flex;align-items: center;justify-content: center;margin: 0 12px 0 0;cursor: pointer;}
.listright_arrow{border: 2px solid rgba(0, 0, 0, 0.10);height: 30px; width: 30px;border-radius: 30px;display: flex;align-items: center;justify-content: center;margin: 0 0 0 12px;cursor: pointer;}
.first{color:#00877a ;}
.list_num h2{margin: 0;font-size: 14px;}
.test-card.all-test ul li:last-child {padding: 12px;border-bottom: 1px solid rgba(0, 0, 0, 0.10);}
.all-test .pagin {padding: 0 12px 15px 0;}
.notific{background-color: #fff;border: 1px solid rgba(0, 0, 0, 0.10);border-radius: 10px; padding: 17px 0;;}
.notific_inner{display: flex;align-items: center}
.notific_head h2{margin: 35px 0 12px 0;}
.notific_img {border: 1px solid rgba(0, 0, 0, 0.10);padding: 13px;border-radius: 40px;margin: 0 0 0 17px;}
.notific_cont p{font-size: 14px;font-weight: 400;line-height: 25px;color: #2d2926;font-family: 'Raleway', sans-serif;margin: 0 0 2px 17px;}
.notific_cont h4{margin: 0 0 0 17px;font-size: 12px;font-weight: 500;line-height: 20px;color: #2d2926;font-family: 'Raleway', sans-serif;}
.notific .pagin{margin: 0 17px 0 0;}
.foot_bg{background-color: #2D2926;padding: 12px 0;margin: 42px 0 0 0;}
.foot_copyright{ display: flex;align-items: center;justify-content: space-between;}
.foot_copyright h4{margin: 0;}
.footer-bg{background-color: #2D2926;margin: 40px 0 0 0;}
.text-muted{color: #fff !important; font-size: 12px;}
.policy a{color: #fff ; font-size: 12px;}
.sb-nav-fixed #layoutSidenav #layoutSidenav_content {padding-left: 289px;}
.new_test h4{margin: 0 0 12px 0;}

/* assessment css code */
.breadcrumb{display: flex;align-items: center;}
.breadcrumb .breadcrumb-item a{background-color: #00877a;padding: 8px 24px;color: #fff;border-radius: 40px;margin: 0 10px 0 0;text-decoration: none;}
.asse_test a{text-decoration: none;color: #fff;}
.header-content h1 { padding: 0 0 33px 0;}
.grade_content h2{margin: 0 0 22px 0;color: #000;font-size: 21px;font-weight: 800;line-height: 43px;font-family: 'Urbanist', sans-serif;}
.activity-sec ul{padding:0;display:flex;align-items:center;flex-wrap:wrap;position:relative;z-index:999;list-style: none;padding: 0;margin: 0;}
.activity-sec ul li{width:auto;background:transparent;border:0;margin:0 10px 0 0;padding:0;position: relative;}
.activity-sec ul li input[type="radio"]:checked ~ a{background: #00877a;color:#fff;border: 1px solid #00877a;}
.activity-sec ul li input[type="radio"] {position: absolute;left: 0;right: 0;width: 100%;height: 100%;opacity: 0;z-index: 99999;cursor: pointer;}
.date-slot{border-radius: 10px;border: 1px solid rgba(0, 0, 0, 0.10);background: #FBFBFB;padding: 20px;}
.date-slot.activity-sec a {text-align: center;border: 1px solid #2D2926;overflow: hidden;color: #000;font-size: 16px;display: block;margin: 0 auto 10px;padding: 15px 40px!important;border-radius: 45px;text-decoration: none;font-weight: 600;font-family: 'Raleway', sans-serif;}
.activity-sec [type="radio"]:focus { background: transparent; box-shadow: none;outline: 0!important;outline: none!important;
-webkit-tap-highlight-color: transparent!important;}
.activity-sec ul li input[type="radio"]:checked ~ a svg path{stroke: #fff !important;}
.table_head h2{margin: 41px 0 0 0;color: #000;font-size: 21px;font-weight: 800;line-height: 43px;font-family: 'Urbanist', sans-serif;}
.table_head p{font-size: 14px;font-weight: 700;line-height: 16px;color: #2d2926;margin:0;}
.cart_btn a{color: #00877a;padding: 11px 25px;border: 1px solid #00877a;border-radius: 40px;font-size: 15px;font-weight: 600;line-height: 17px; font-family: 'Raleway', sans-serif;}
.cart_btn a:hover{background-color: #2D2926;color: #fff;border: 1px solid #2D2926;}
.table > :not(caption) > * > *, .datatable-table > :not(caption) > * > * {padding: 21px 0;}
.tbl_bg{border-radius: 10px;border: 1px solid rgba(0, 0, 0, 0.10);background: #FBFBFB;padding: 14px 0;margin: 26px 0 15px 0;}
.tbl_bg .table {white-space: nowrap;margin: 0 !important;}
.table tbody tr td:first-child{padding: 21px 0 21px  31px ;}
.table thead tr th:first-child{padding: 21px 0 21px  31px ;}
.table thead tr th{font-size: 16px;font-weight: 700;color: #000;line-height: 18px;font-family: 'Raleway', sans-serif;}
.table tbody tr td{font-size: 15px;font-weight: 500;color: #2D2926;line-height:17px;font-family: 'Raleway', sans-serif;}
.table tbody tr td button{background-color: transparent !important;border: none;color: #2D2926;padding: 0;}
.table tbody tr td .btn:focus-visible {border-color: transparent;box-shadow:none;}
.tbl_bg .pagin{margin: 14px 35px 0 0;}
.tbl_content ol{margin: 0;padding: 0 0 0 16px;}
.tbl_content ol li{font-size: 14px;font-weight: 700;line-height: 30px;font-family: 'Raleway', sans-serif;}
.tbl_content ol li .join{color: #00877a;}
#layoutSidenav_content .table .btn:hover {color: #2D2926;}
.modal-header { border-bottom: none;}
.modal-body .table tbody tr:last-child td {border: 0}

/* cart page css code */
.cross_icon{border: 1px solid #00877a;height: 30px;width: 30px;display: flex;justify-content: center;align-items: center;border-radius: 110px;cursor: pointer;}
.cart .table tbody tr:last-child td{border: 0;}
.cart_sec .tbl_bg{padding: 0;}
.coupon_content h4{font-size: 18px;font-weight: 800;line-height: 30px;color: #000;font-family: 'Urbanist', sans-serif;margin: 19px 0 16px 0;}
.imp_content h4{font-size: 14px;line-height: 20px;font-family: 'Urbanist', sans-serif;font-weight: 800;color: #000;margin: 19px 0 7px 0;}
.imp_content ol li{font-size: 14px; font-weight: 500;color: #2D2926;line-height: 25px;font-family: 'Raleway', sans-serif;}
.imp_content ol{margin: 0;padding: 0 0 0 16px;}
.coupon_content input{width: 100%;max-width: 320px;padding: 16px 0 16px 30px;border-radius: 30px 0px 0px 30px;border: 1px solid rgba(0, 0, 0, 0.10);background: #FBFBFB;outline-color: #00877a;}
.coupon_inner{display: flex;}
.coupon_inner button{border: none;background-color: #00877a;border-radius: 0 30px 30px 0;color: #fff;font-size: 16px;font-weight: 600;line-height: 18px;font-family: 'Raleway', sans-serif;height: 60px;min-width: 160px;}
.coupon_inner button:hover{background-color: #2D2926;}
.cart_total h4{font-size: 21px;font-weight: 800;line-height: 43px;font-family: 'Urbanist', sans-serif;margin: 24px 0 0 0;}
.cart_total .table tbody tr:last-child td{border: none;}
.cart_total .table tbody tr:last-child th{border: none;}
 .checkout_btn a{background-color: #00877a;font-size: 16px;font-weight: 600;line-height: 18px;font-family: 'Raleway', sans-serif;padding: 15px 36px;border-radius: 30px;border: none;color: #fff;margin: 35px 0 0 0;}
.checkout_btn a:hover{background-color: #2D2926;}
.cart_sec .table_head h2{margin: 0;}

/* checkout page css code */
.checkout_field{border-radius: 7px;border: 1px solid rgba(0, 0, 0, 0.10);background: #FBFBFB;padding: 45px;}
.star_symb{color: #00877a;}
.checkout_field input{padding: 13px 0 13px 40px;}
.checkout_field .icon{position: relative;position:absolute;margin: 12px 0 0 10px;}
.form-control:focus {box-shadow: none;border-color: #00877a;}
.checkout_field label{font-size: 14px;font-weight: 600;line-height: 16px;color: #000;font-family: 'Raleway', sans-serif;}
.checkout_field select{padding: 13px 0 13px 40px}
.form-select:focus, .datatable-selector:focus {border-color: #00877a;box-shadow: none;}
.checkout_field .imp_content ol li{color: #00877a;}
.checkout_tbl_cont .table tbody tr td{padding: 21px 0 21px 0;}
.checkout_tbl_cont h2{margin: 27px 0 10px 0;}
.checkout_tbl_cont .table tbody tr:last-child th{border: 0;}
.payment_method{border-radius: 7px;border: 1px solid rgba(0, 0, 0, 0.10);background: #FBFBFB;padding: 24px;}
.paypal{display: flex;align-items: center; justify-content: space-between; border-radius: 4px;border: 1px solid #D6ECD9;background: #FFF;padding: 14px;}
.paypal_inner{display: flex;align-items: center;}
.paypal_inner h4{margin: 0 0 0 7px;color: #262628;font-size: 14px;font-weight: 400;line-height: normal;font-family: 'Poppins', sans-serif;}
.form-check-input:checked {background-color: #00877a;border-color: #00877a;}
.form-check-input:focus {box-shadow:none;}
.payment_method .num{border: 1px solid #EFEFF4;}
.add_payment{display: flex;align-items: center;}
.add_payment a{margin: 0 0 0 7px;font-size: 16px;font-weight: 700;font-family: 'Raleway', sans-serif;color: #00877a;}
.payment_method .checkout_btn {margin: 35px 0 0 0 ; }
.payment_method .checkout_btn a{padding: 10px 49px;}
.payment_method h2{margin: 0 0 12px 0;font-size: 21px;font-weight: 800;line-height: 43px;color: #000;}
.add_detail{border-radius:0 0 4px  4px  ;}
.cart_sec .choose{border-radius: 4px 4px 0 0 ;}
.add_detail .detail_field{display: flex;justify-content: space-between;}
.detail_field input{padding: 20px 16px;width: 100%;}
.add_detail .detail_field .card-input {width: 100%;padding: 0 8px;}
.add_detail .detail_field .card-input:nth-child(2), .add_detail .detail_field .card-input:nth-child(3),.add_detail .detail_field .card-input:nth-child(5) {width: 40%;}

/* my study room css code */
.study_room.date-slot.activity-sec a {font-size: 18px;padding: 0 !important; height: 35px;width: 35px;}
.study_tbl .table thead tr th:first-child {padding: 21px 0 21px 0;}
.study_tbl .table tbody tr td:first-child{padding: 21px 0 21px 0;}
.study_tbl .table thead tr th {text-align: center;}
.study_tbl .table tbody tr td{text-align: center;line-height: 25px;}
.study_head h2{margin: 75px 0 16px 0;}
.study_tbl{padding: 0;}
.study_attd{margin: 0;padding: 0;}
.study_attd .table tbody tr:last-child td{border: 0;}

/* academic info css code */
.choice_head{margin: 0 0 16px 0;}
.choice_field  input {padding: 13px 0 13px 13px;}
.choice_field select {padding: 13px 0 13px 13px;}
.choice_radio{display: flex;align-items: center; }
.choice_radio input{padding: 0;height: 25px;width: 25px; }
.choice_radio .form-check{display: flex;align-items: center; margin: 19px 0 32px 54px;}
.choice_radio .form-check:first-child{margin: 19px 0 32px 0 ;}
.choice_radio label{margin: 0 0 0 11px;}
.marks h4{font-size: 18px;font-weight: 800;line-height: 33px;color: #000;font-family: 'Urbanist', sans-serif;margin: 17px 0 17px 32px;}
.choice_field{padding: 0;}
.ability{padding: 24px 32px;}
.cut_off{padding: 24px 32px;}
.cut_head h4{margin: 0;}
.cut_sub_btn {background-color: #00877a;font-size: 16px;font-weight: 600;line-height: 18px;font-family: 'Raleway', sans-serif;width: 150px; height: 50px; border-radius: 30px;display: flex;align-items: center;justify-content: center;}
.cut_sub_btn:hover{background-color: #2D2926;}
.cut_sub_btn a{color: #fff;padding: 15px 48px;}
.guideline_head{margin: 41px 0 7px 0;font-size: 21px;font-weight: 800;line-height: 43px;color: #000;font-family: 'Urbanist', sans-serif;}
.text_para p{font-size: 14px;font-weight: 400;line-height: 24px;font-family: 'Raleway', sans-serif;color: #2d2926;}
.guideline_list ol{padding: 0 0 0 16px;margin: 0 0 10px 0;}
.guideline_list ol li{font-size: 14px;font-weight: 400;line-height: 26px;color: #2d2926;}
.guideline_list ol p{margin: 0;font-size: 14px;font-weight: 400;line-height: 26px;color: #2d2926;}
.consul h4{font-size: 21px;font-weight: 800;line-height: 43px ;color: #000;font-family: 'Urbanist', sans-serif;margin: 16px 0 7px 0;}
.consul ol{padding: 0 0 0 16px ;margin: 0;}
.consul ol li{font-size: 14px;font-weight: 400;line-height: 24px;color: #2d2926;font-family: 'Raleway', sans-serif;}

/* selective high school css code */
.selective_head h4{margin:0;font-size: 21px;font-weight: 800;line-height: 43px;color: #000;font-family: 'Urbanist', sans-serif;}
.selective_content ol{padding: 0 0 30px 16px;margin: 14px 0 0 0;}
.selective_content ol li{font-size: 14px;font-weight: 400;line-height: 26px;color: #2d2926;font-family: 'Raleway', sans-serif;}
.selective .table tbody tr th:first-child {padding: 21px 0 21px 31px;}
.selective .table tbody tr td:first-child {width: 40%;}
.full_select{margin: 25px 0;}
.selective .table tbody tr th:first-child {width: 40%;}


/* profile css code */
.profile_title{display: flex;align-items: center;justify-content: space-between;padding: 25px 33px;}
.profile_title h2{margin: 0;}
.edit_profile a{background-color:#00877a;color: #fff;padding: 12px 29px;font-size: 14px;font-weight: 700;line-height: 17px;border-radius: 45px;font-family: 'Raleway', sans-serif; }
.change-password a {background-color: #00877a;color: #fff;padding: 12px 29px;font-size: 14px;font-weight: 700;line-height: 17px;border-radius: 45px;font-family: 'Raleway', sans-serif;}
.edit_profile a:hover{background-color: #2D2926;color:#fff;}
.change-password a:hover{background-color: #2D2926;color:#fff;}
.profile_shade{border-radius: 10px;border: 1px solid rgba(0, 0, 0, 0.10);background: #FBFBFB;}
.profile_inner_cont h2{margin: 15px 0 2px 32px;font-size: 14px;font-weight: 700;color: #000;font-family: 'Poppins', sans-serif;}
.profile_inner_cont h4{margin: 0 0 37px 32px; font-size: 16px;font-weight: 400;color: #545454;line-height: 24px;font-family: 'Poppins', sans-serif;}
.profile_cont{display: flex;
     /* justify-content: space-between; */
     padding: 25px 33px;}
.profile_inner_cont h4:last-child{margin: 0 0 0 32px;}
.profile_main{display: flex;}
.profile_add h2{margin: 16px 0 16px 25px;font-size: 24px;font-weight: 700;line-height: 28px;color: #000;font-family: 'Urbanist', sans-serif;}
.profile_inner_add h2{margin: 0 0 37px 0;}
.profile_inner_add h4{margin: 0 0 37px 0;}
.profile_inner_add h4:last-child{margin: 0;}
.profile_inner_add h2{margin: 0 0 2px 0;}
.pass{justify-content: start;}

/* editprofile css code */
.edit_field{padding: 0;border: none;}
.update_feild{padding: 25px;}
.contact_field{padding: 25px;border: none;}
.parent_field{padding: 25px;border: none;}
.edit_icon{border: 1px solid #000;height: 45px; width: 45px;border-radius: 45px;display: flex; align-items: center;justify-content: center;bottom: -8px;right: -8px; position: absolute;background-color: #fff;}


/* notification css code */
.not_bell_icon{border: 1px solid #545454;border-radius: 50px;padding: 13px;} 
.notific_sec{display: flex;align-items: center;justify-content: space-between; padding: 18px 0 18px 0;}
.notific_sec p{margin: 0 0 0 15px;font-family: 'Poppins', sans-serif;font-size: 14px;font-weight: 400;line-height: 21px; max-width: 800px;}
.notific_sec h4{margin: 0;font-size: 12px;font-weight: 400;line-height: 18px;color: #79747E;font-family: 'Poppins', sans-serif;}
.notific_inner_sec{display: flex;align-items: center;}
.notific_main{padding: 0 28px;}

/* join membership css code */
.join_memberleftcont{padding: 0 20px;}
.join_head h4{margin: 0 0 17px 0; font-size: 24px;font-weight: 700;line-height: 28px;color: #000;font-family: 'Urbanist', sans-serif;}
.slick-dots li {width: auto;height: auto;margin: 0;display: inline-block;}
.slick-dots li.slick-active button:before {font-size: 0;width: 25px;height: 5px;background: #00877a;top: 7px;border-radius: 30px;left: -2px;}
.slick-dots li button {  width: 25px;}
.slick-dots li button:before{color: #00877a !important;}
.joinmen_img img{border-radius: 10px;overflow: hidden;}
.tutorial h4{margin:0; padding:  30px 0 17px 0;}
.member_title{display: flex;justify-content: space-between;align-items: center;margin: 0 0 25px 0;}
.member_title h2{margin: 0;font-size: 24px;font-weight: 700;line-height: 28px;color: #000;}
.member_title h4{margin: 0;font-size: 16px;font-weight: 600;line-height: 40px;}
.member_title h4 a{color: #00877a;text-decoration: underline !important;}
.info_cont h4{margin: 38px 0 24px 0;}
.info_field {position: relative;}
.info_field select{padding: 13px 0 13px 40px;}
.info_field input{padding: 13px 0 13px 40px;}
.info_field label{font-size: 14px;font-weight: 600;color: #000;}
.work_icon{position: absolute;top: 44px;left: 10px;}
.next_btn{margin: 20px 0 0 0;}
.next_btn a{background-color: #00877a;padding: 8px 50px;border-radius: 45px;color: #fff;font-size: 16px;font-weight: 700;line-height: 28px;font-family: 'Raleway', sans-serif;}
.next_btn a:hover{background-color: #2D2926;}
.join_tab .nav-link {font-size: 16px;font-weight: 600;color: #2D2926 ;}
.join_tab .nav-link.active {background-color: #2D2926;color: #fff;}
.join_tab .nav-link.active{position: relative;}
.join_tab .nav-link.active::before {position: absolute;content: "";height: 15px;width: 15px;border-radius: 2px;transform: rotate(-45deg);background-color: #2D2926;bottom: -5px;left: 43%;}
.tab_out{border-radius: 10px;border: 1px solid rgba(0, 0, 0, 0.10);background: #FBFBFB;}
.member_cont{text-align: center;}
.tab_out .tab_out_cont{padding: 0 38px 45px 38px;}
.member_cont h4{margin: 64px 0 9px 0;font-size: 24px;font-weight: 800;line-height: 36px;color: #000;font-family: 'Urbanist', sans-serif;}
.member_cont p{margin: 0;font-size: 14px;font-weight: 500;line-height: 28px;color: #2d2926;font-family: 'Raleway', sans-serif;}
.group_img{display: flex;justify-content: space-between;margin: 65px 0 0 0;flex-wrap: wrap  ;}
.group_inner_img img {border-radius: 120px;border: 1px solid rgba(0, 0, 0, 0.10);background: #FFF;padding: 21px 38px;}
.group_inner_img{width: 100%;max-width: 200px;padding: 0 0 12px 0;}
.relod_cont{display: flex;align-items: center;}
.relod_cont input{padding: 15px 10px;outline-color: #00877a;border-radius: 5px;border: 1px solid #79747E;background: #FFF;width: 100%;max-width: 105px;}
.relod_cont h2{font-size: 21px;font-weight: 700;line-height: 31px;color: #79747E;margin: 0 15px 0 15px;font-family: 'Poppins', sans-serif;}
.relod_cont h4{margin: 0;color: #79747E;font-size: 14px;font-weight: 600;line-height: 21px;font-family: 'Poppins', sans-serif;text-decoration: underline !important;}
.login_cont{border-radius: 10px;border: 1px solid rgba(0, 0, 0, 0.10);background: #FBFBFB;padding: 0 38px 45px 38px;}
.log_tab  li a{color: #2d2926;font-size: 14px;font-weight: 600;font-family: 'Raleway', sans-serif;}
.log_tab  li a:last-child{margin: 0 0 0 10px;}
.lapii_img {position: relative;}
.lapii_img p{position: absolute;bottom: 0;left: 10px;right: 10px;color: #fff;font-size: 14px;font-weight: 600;line-height: 25px;font-family: 'Raleway', sans-serif;}

/********table actions********/
.inner-actions {display: flex;justify-content: center;}
.edit-action {margin-right: 20px;cursor: pointer;}
.trash-action {margin-right: 20px;cursor: pointer;}

.dual-sec{display: flex;padding: 10px;}

.dual-sec {display: flex;justify-content: space-between;align-items: center;  }
  
.page-heading {flex: 1; /* Take as much space as possible */}
  
.add-btn {margin-left: 10px; /* Adjust the margin as needed */}


.back-to{ border-bottom: 2px solid;}


/****************Employee details*****************/


#employee-pro .employee-profile {
    max-width: 800px;
    margin: 50px auto;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
}

#employee-pro .profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
}

#employee-pro .employee-details {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

#employee-pro .employee-info {
    flex: 1;
}

#employee-pro .employee-info h2 {
    margin: 0 0 10px;
}

#employee-pro table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

#employee-pro th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

#employee-pro th {
    background-color: #f2f2f2;
}
.submenu-items {
    margin-left: 25px;
}

/*********************/

#admin-roles-permi .card {
    margin-top: 20px;
  }
  
  #admin-roles-permi .admin-roles {
    margin-bottom: 20px;
  }
  
  #admin-roles-permi .admin-roles label {
    font-weight: bold;
  }
  
  #admin-roles-permi .admin-roles select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  #admin-roles-permi .admin-roles-permissions {
    padding: 20px 0;
  }
  
  #admin-roles-permi .permissions-check-p-head {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  #admin-roles-permi .permissions-check-p-inner {
    display: flex;
    align-items: center;
  }
  
  #admin-roles-permi .permissions-check-p-inner input {
    margin-right: 5px;
  }
  a.back-to {
    position: absolute;
    right: 100px;
    }
  /* Responsive styles - adjust as needed */
  @media (max-width: 767px) {
    #admin-roles-permi .col-md-3 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  


/* media query css code */
@media(max-width:1199px) {
    .add_payment{margin: 12px 0 0 0;}
}

@media (max-width: 991px) {
	 .sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
		left: 0px;
	}
	#layoutSidenav #layoutSidenav_nav {
		flex-basis: 300px;
		transform: translateX(-300px);
	}
	.sb-nav-fixed.sb-sidenav-toggled #layoutSidenav {
		padding: 0 0 0 10px;
	}
	.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav {
		margin: 65px 0 0;
		border-radius: 0px 10px 10px 0px;
	}
	#layoutSidenav {
		padding: 0 0px 0 10px; 
	}
    .h_line{margin: 0 7px !important;}
    .coupon_content input{max-width: 100%;}
    .add_detail .detail_field .card-input:nth-child(2), .add_detail .detail_field .card-input:nth-child(3),.add_detail .detail_field .card-input:nth-child(5) {width: 100%;}
    .add_detail .detail_field{flex-direction: column;}
    .detail_field input{margin: 0 0 12px 0;}
    .detail_field .card-input:last-child input{margin: 0;}
    .profile_menimg{margin: 0 0 15px 0;}
    .group_inner_img {max-width: 170px;}
    .log_tab  li a:last-child{margin: 0 10px 0 10px !important;}

    
}
@media (max-width: 767px) {
	.sb-topnav .navbar-brand {
		width: 150px;
		padding: 0px !important;
	}
	.sb-topnav .navbar-brand img {
		width: 90%;
	}
	/* button#sidebarToggle {;
		margin: 0 !important;
	} */
	.nav-item.dropdown a {
		margin: 0 10px 0 0;
	}
	.profile-pic img {
		width: 40%;
	}
	.profile-pic {
		margin: 0 0 10px;
	}
	.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav {
		margin: 65px 0 0;
		padding: 15px 0px;
		border-radius: 0;
		background: #fff;
	}
	.sb-sidenav-dark .sb-sidenav-menu .nav-link {
		color: rgb(0 0 0);
		padding: 12px 20px !important;
		font-size: 14px;
	}
	.sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
		width: 250px;
	}
	.header-content {
		padding: 0 20px;
	}
	.header-content h1 {
		max-width: 100%;
	}
    
    .sb-nav-fixed .sb-topnav {
        padding: 7px 15px;
    
    }
    .top_bar_inner h4 {
        font-size: 9px ;
    }
    .update_feild {
        padding: 0px;
    }
    .notific_title {align-items: start; }
    .notific_title h2{margin: 15px 0 15px 28px !important;}
    .edit_profile{height: 45px;width: 165px;}
    .selective .table tbody tr th{width: 100%;min-width: 100px}
    .selective .table tbody tr td {width: 100%;min-width: 100px;}
    .myheader {padding: 0px 15px 0;}
    .top_bar{padding: 5px 0 0 0 ;}
    .sch_event_main {flex-direction: column;align-items: start;}
    .sch_view {margin: 10px 0 0 0;width: 100% ;}
    .sch_view button{width: 100%;}
    .text-muted{margin: 0 0 12px 0;}
    .evnt_head h2 {font-size: 15px ;line-height: 20px;}
    .event h2 {margin: 0px 0 12px 0;}
    .test-card.all-test {margin: 35px 0 0 0;}
    .top_bar_inner {width: 50%;}
    .date-slot.activity-sec a svg { width: 17px; margin: 0 2px 0 0px;}
    .date-slot.activity-sec a {font-size: 13px;padding: 8px 15px!important;}
    .activity-sec ul{justify-content: center;}
    .breadcrumb .breadcrumb-item a { padding: 6px 13px;}
    .nav-item.dropdown a {width: 30px;height: 30px; }
    button#sidebarToggle {width: 30px;height: 30px;padding: 0; }
    .coupon_inner{flex-direction: column;}
    .coupon_inner button{width: 160px; border-radius: 30px;margin: 12px 0 0 0;}
    .coupon_content input{border-radius: 30px;}
    button#sidebarToggle svg {height: 20px;width: 20px;}
    .paypal{margin: 0 0 15px 0;}
    .checkout_field {padding: 15px;}
    .date-slot {padding: 10px;}
    .choice_radio{flex-direction: column;align-items: start;}
    .choice_radio .form-check{margin: 0 0 12px 0;}
    .choice_radio .form-check:first-child{margin: 0 0 12px 0 ;}
    .choice_field{padding: 0;}
    .profile_cont{flex-direction: column;padding: 10px 15px;}
    .profile_inner_cont h2{margin: 0 0 2px 0;}
    .profile_inner_cont h4{margin: 0 0 0 0;font-size: 14px;}
    .profile_inner_cont h4:last-child{margin: 0;}
    .profile_title {flex-direction: column;padding: 0;}
    .profile_title h2 {margin: 0 0 15px 0;}
    .profile_add h2 {margin: 10px 0 10px 15px;}
    .men_add{margin-left: 15px;}
    .profile_menimg{margin: 15px 0 15px 0;}
    .notific_sec {flex-direction: column;align-items: start;}
    .notific_inner_sec {flex-direction: column;align-items: start;}
    .notific_sec p {margin: 15px 0 7px 0;}
    .group_inner_img {max-width: 145px;}
    .tab_out .tab_out_cont{padding: 0 15px 25px 15px;}
    .member_title {flex-direction: column;align-items: start;}
    .member_cont h4 {margin: 30px 0 9px 0;}
    .login_cont{border-radius: 10px;border: 1px solid rgba(0, 0, 0, 0.10);background: #FBFBFB;padding: 0 15px 25px 15px;}
@media (max-width: 320px) {
    .top_bar_inner{width:auto; }
}
}




/*********************************/

.sec1{height: 100vh;background-color: blueviolet;}

.sec2{height: 90vh;}

.sec3{height: 50vh;background-color: blueviolet;}

.sec4{height: 90vh;}

.sec5{height: 70vh;background-color: blueviolet;}

.sec6{height: 60vh;}

.sec7{height: 40vh;background-color: blueviolet;}

.floating1 {width: 130px;height: 84px;display: flex;align-items: center;justify-content: center;border-radius: 50px;flex-wrap: wrap;position: fixed;bottom: 45px;left: 10px;}

.dark{
  background: #000;color: #fff;
}

.light{
  background: #fff;color: #000;
}


.badge {
    display: inline-block;
    padding: 0.5em 1em;
    background-color: #007bff; /* Set your desired background color */
    color: #fff; /* Set your desired text color */
    border-radius: 0.25em; /* Adjust border-radius for rounded corners */
}

td{
    text-transform: capitalize;
}

.no-space{
    padding: 13px 0 13px 20px !important;
}

.clickable{cursor: pointer;}




.project-details-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .project-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .project-leaders {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .team-members {
    margin-top: 20px;
  }
  
  .team-members ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .team-members li {
    margin-bottom: 10px;
  }
  
  .team-members li::before {
    content: "\2022";
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  .custom-link {
    color: inherit; 
    text-decoration: none;
  }
  
  .custom-link:hover {
    text-decoration: underline; 
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f0f0f0;
  }