@import "./style/custom.css";
@import "./style/styles.css";

.log_bg {border-radius: 2px;height: auto;margin: 1rem;width: 400px;box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);transition: all 0.3s cubic-bezier(.25,.8,.25,1);position:absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.sign_btn button{background-color: #00877a!important;border: none; width: 100%; }
.login_head i {font-size: 30px ; }
.bg_main{height: 50vh;background-color:#00877a;}
.forget_pas a{text-decoration: none;color: #00877a;font-weight: bold;}
.log_bg label {font-size: 13px;}

.side_main{background-color: #343a40;}
.sidebar img {width: 100%;height: 100%;object-fit: cover;}
.side_profile_img {max-width: 88px;margin: 0 auto;}
.side_admin_name {text-align: center;font-weight: 600;font-size: 20px;}
.side_list ul {list-style-type: none;padding: 10px;}
.side_list ul li a {text-decoration: none;padding: 10px;color: #446cc7;}
.side_list li {padding: 10px;}
.side_logout {padding: 12px 25px;}

.right_head{background-color: green;}
.main_content {margin: 35px 0;}
.head_prf_name {
    font-size: 14px;
    padding: 10px;
    color: #000;
}
.head_profile img {
    width: 34px;
}
.head_profile button {
    background: none;
    border: none;
}
.head_profile button:hover, .head_profile button:active, .head_profile button:focus, .head_profile button.show {
    background: none !important;
    border: none !important;
}
.head_profile.dropdown img {
    width: 40px;
    border-radius: 20px;
    height: 38px;
}

.profile-pic img {
    width: 145px;
    height: 145px;
    border-radius: 100px;
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link.active svg path {
    fill: #fff !important;
}

.profile_menimg {
    width: 160px;
    height: 160px;
    border-radius: 20px;
    overflow: hidden;
}

.profile_menimg img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

#edit_profile_pg .profile_menimg {
    width: 134px;
    height: 134px;
}

#edit_profile_pg .edit_icon svg {

    position: absolute;
    display: flex;
    justify-content: center;
}
